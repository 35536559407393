/* eslint-disable class-methods-use-this */
import _axios, { get, post, put, _delete } from '@/lin/plugin/axios'

class WhiteList {
  // 获取白名单规则列表
  async getWhiteListRule(data) {
    const res = await get('/v1/white_list', data)
    return res
  }

  // 获取白名单规则列表-全部
  async getWhiteListRuleAll(data) {
    const res = await get('/v1/white_list/all', data)
    return res
  }

  // 新增白名单规则
  async addWhiteListRule(data) {
    const res = await post('/v1/white_list', data)
    return res
  }

  // 编辑白名单规则
  async editWhiteListRule(id, data) {
    const res = await put(`/v1/white_list/${id}`, data)
    return res
  }

  // 删除白名单规则
  async delWhiteListRule(id, data) {
    const res = await _delete(`/v1/white_list/${id}`, data)
    return res
  }

  // 导入白名单-添加
  async appendWhiteList(id, data) {
    const res = await put(`/v1/white_list/${id}/import/append`, data)
    return res
  }

  // 导入白名单-覆盖
  async replaceWhiteList(id, data) {
    const res = await put(`/v1/white_list/${id}/import/replace`, data)
    return res
  }

  // 检测白名单
  async checkWhiteList(id, data) {
    const res = await get(`/v1/white_list/${id}/check`, data)
    return res
  }
}

export default new WhiteList()
