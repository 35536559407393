<template>
  <el-dialog
    ref="dialog"
    title="导入白名单"
    :model-value="dialogVisible"
    :append-to-body="true"
    :before-close="handleClose"
    width="500px"
    :close-on-click-modal="false"
    :z-index="1000"
  >
    <el-form ref="form" label-width="110px">
      <el-form-item label="白名单">
        <el-upload
          v-model:file-list="fileList"
          multiple
          :on-remove="handleRemove"
          :before-upload="beforeUpload"
          accept=".xls,.xlsx"
          :auto-upload="false"
          :limit="limit"
          class="upload"
          ref="upload"
          :http-request="uploadHttpRequest"
          :class="{ hidden: fileList.length >= limit }"
        >
          <el-button type="primary" plain>上传</el-button>
        </el-upload>
        <div class="tips-red">导入的MD5值需为大写的加密串</div>
      </el-form-item>
      <el-form-item label="导入成功" v-if="result.success_count > 0">
        <div>共 {{ result.success_count }} 条</div>
      </el-form-item>
      <el-form-item label="导入失败数据" v-if="result.fail_log && result.fail_log.length">
        <div>{{ result.fail_log.join('，') }}</div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm('append')" :disabled="loading" :loading="loading"
          >添加上传</el-button
        >
        <el-button type="primary" @click="handleConfirm('replace')" :disabled="loading" :loading="loading"
          >覆盖上传</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, getCurrentInstance } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus/lib/components'
import whiteModel from '@/model/white-list'

export default {
  emits: ['confirm'],
  setup() {
    const dialogVisible = ref(false)
    const fileList = ref([])
    const loading = ref(false)
    const limit = ref(1)
    const curItem = ref({})
    const handleType = ref()
    const result = ref({})
    const handleOption = ref({
      append: {
        api: 'appendWhiteList',
        tips: '【添加上传】会将新数据添加到原有白名单数据中，是否继续？',
      },
      replace: {
        api: 'replaceWhiteList',
        tips: '【覆盖上传】会将原有白名单数据覆盖，且原有白名单数据关联的活动将会失效，是否继续？',
      },
    })
    const ctx = getCurrentInstance()

    const init = item => {
      dialogVisible.value = true
      curItem.value = item
      result.value = {}
      fileList.value = []
    }

    const beforeUpload = uploadFile => {
      fileList.value.push(uploadFile)
    }

    const handleRemove = (response, uploadFile, uploadFiles) => {
      fileList.value.splice(0, 1)
    }

    const handleConfirm = async type => {
      if (!fileList.value.length) return ElMessage.warning('请先上传文件')
      handleType.value = type
      await ElMessageBox.confirm(handleOption.value[handleType.value].tips, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      ctx.refs.upload.submit()
    }

    const uploadHttpRequest = async file => {
      const formData = new FormData() // FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append('import_file', file.file) // 添加文件对象
      try {
        loading.value = true
        let res = await whiteModel[handleOption.value[handleType.value].api](curItem.value.id, formData)
        if (res.fail_log.length > 0) {
          result.value = res
          fileList.value = []
        } else {
          ElMessage.success(`导入成功，共${res.success_count}条数据`)
          handleClose()
        }
      } finally {
        ctx.emit('confirm')
        loading.value = false
        console.log(err)
      }
    }

    const handleClose = done => {
      dialogVisible.value = false
      loading.value = false
      if (typeof done === 'function') done()
    }

    return {
      dialogVisible,
      fileList,
      limit,
      loading,
      handleType,
      result,
      init,
      beforeUpload,
      handleRemove,
      uploadHttpRequest,
      handleConfirm,
      handleClose,
    }
  },
}
</script>

<style lang="scss" scoped>
.upload {
  width: 100%;
  &.hidden {
    ::v-deep .el-upload {
      display: none !important;
    }
    ::v-deep .el-upload-list {
      margin: 0;
    }
  }
}
</style>
